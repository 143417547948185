@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Nunito Sans Regular"),
    local("NunitoSans-Regular"),
    url(/fonts/NunitoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local("Nunito Sans SemiBold"),
    local("NunitoSans-SemiBold"),
    url(/fonts/NunitoSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Nunito Sans Bold"),
    local("NunitoSans-Bold"),
    url(/fonts/NunitoSans-Bold.ttf) format("truetype");
}

:root {
  /* Should match moxie_suite_ui/config/mui/colorPalette.ts */
  --moxie-violet-90: #8a1d96;
  --moxie-gray-50: #aeaeb7;
  --moxie-error-color: #d21a29;
  --moxie-primary-color: #8a1d96;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

a,
a:visited {
  text-decoration: initial;
}

.MuiButton-textSizeSmall {
  font-size: 14px;
  font-weight: bold;
}
.MuiTypography-root p {
  margin: 0;
}

.sigCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid var(--moxie-gray-50);
  border-radius: 16px;
}

.PhoneInputBox {
  color: var(--moxie-gray-50);
  position: relative;
  box-sizing: border-box;

  border-radius: 12px;
  padding: 1px;
}

.PhoneInputBox:focus-within .PhoneInputLegend {
  color: var(--moxie-primary-color);
}

.PhoneInputLegend {
  position: absolute;
  z-index: 1;
  background: white;
  color: gray;
  top: -9px;
  left: 10px;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  font-family: "Nunito Sans";
  font-weight: 400;
}

.PhoneNumberLabel {
  position: absolute;
  z-index: 1;
  color: gray;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  font-family: "Nunito Sans";
  font-weight: 400;
  transform-origin: top left;

  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
  font-size: 16px;

  top: -25px;
  left: -3px;
  transform: translate(14px, 16px) scale(0.75);
}

.PhoneNumberLabelEmpty {
  top: 1px;
  left: 65px;
  font-size: 16px;
  transform: translate(14px, 16px) scale(1);
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.PhoneInput:focus-within + .PhoneNumberLabelEmpty {
  top: -25px;
  left: -3px;
  transform: translate(14px, 16px) scale(0.75);
}

.PhoneNumberLabel.error {
  color: var(--moxie-error-color) !important;
}

.PhoneInputLabel {
  top: -25px;
  left: 0px;
  font-size: 16px;
  transform: translate(14px, 16px) scale(0.75);
}

.PhoneInput:focus-within ~ .PhoneInputLabelNotch {
  border: 2px solid var(--moxie-primary-color);
}

.PhoneInput {
  padding: 10px;
  height: 56px;
  align-items: center;
  border: none;
}

.PhoneInputLabelNotch {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}
.PhoneInputLabelNotch.error {
  color: var(--moxie-error-color) !important;
  border: 1px solid !important;
}

.PhoneInput:hover:not(:focus-within) ~ .PhoneInputLabelNotch {
  border: 1px solid black;
}
.PhoneInputLabelNotchPlaceholder {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.PhoneInput:focus-within
  ~ .PhoneInputLabelNotch
  > .PhoneInputLabelNotchPlaceholder {
  max-width: 100%;
}

.PhoneInputLabelNotchPlaceholderHidden {
  max-width: 0px;
}
.PhoneInputLabelNotchPlaceholder > span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.PhoneInputInput {
  height: 100%;
  border: none;
  background: none;
  font: inherit;
  font-size: 1rem;
  outline: none;
  color: black;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-toolbar {
  border-radius: 12px 12px 0px 0px;
}

.ql-container {
  border-radius: 0px 0px 12px 12px;
}

#bookingjs {
  font-family: "Nunito Sans";
  background: transparent;
  box-shadow: unset;
  border: unset !important;
}
/* on dev it sometimes mounts 2 widgets - this hides the additional elements */
#bookingjs > div:nth-child(n + 3) {
  display: none;
}

#bookingjs .bookingjs-calendar .fc-list {
  border: unset !important;
}

.fc-list-event:hover {
  border-left: 3px solid var(--moxie-primary-color) !important;
  color: var(--moxie-primary-color) !important;
}

.disable-long-tap {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.__markerjs2__0_toolbox .__markerjs2__0_toolbox_button {
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 786px) {
  .__markerjs2__0_toolbar .__markerjs2__0_toolbar_button {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 600px) {
  .MuiClockPicker-root {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
  }
}

.fc-timegrid-axis,
.fc-timegrid-slot-label-frame {
  font-size: 14px !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(236, 237, 254, 0.2) !important;
}

.fc .fc-non-business {
  background-color: rgba(198, 198, 210, 0.3) !important;
}

.fc .fc-bg-event {
  opacity: 1 !important;
  border-radius: 8px;
}

.fc .fc-v-event,
.fc .fc-bg-event {
  padding: 4px 8px !important;
  border-radius: 8px !important;
  overflow: hidden;
}

.fc .availability-constraint-tile {
  border-radius: 0 !important;
}

.fc .fc-bg-event {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.fc .fc-v-event {
  border-width: 2px !important;
}

.fc-v-event .fc-event-main {
  color: inherit;
  text-decoration: inherit;
}

.fc-daygrid-day-top {
  justify-content: center;
}

.fc-daygrid-day-frame {
  height: 115px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .fc-daygrid-day-frame {
    height: 140px;
  }
}

.fc-popover {
  opacity: 0;
}

/* Since we are using links to redirect to the calendar day view in month and weekly headers
  we need to make sure the a element takes the whole space so the elements downstream know 
  when to wrap.  
*/
.fc a[data-navlink] {
  max-width: 100%;
}

.disable-pinch-zoom {
  touch-action: pan-x pan-y;
}

.disable-touch-actions {
  touch-action: none !important;
}

.bookingjs-footer-tz {
  display: none;
}

.otp-input:focus {
  outline: transparent;
  border-color: var(--moxie-primary-color) !important;
}

/* Bird Eats Bug Customization - see `components/common/birdEatsBugWebSDK/birdEatsBugWebSDK.tsx` */
#birdeatsbug-sdk {
  --button-primary-bg-color: var(--moxie-primary-color);
  --button-primary-hover-color: var(--moxie-primary-color);
}

.ptr,
.ptr__children {
  min-height: 100vh;
  overflow: visible !important;
}
